{
  "header": {
    "menu": {
      "developers": "Developers",
      "advantages": "Advantages",
      "catalogs": "Catalogs",
      "functionality": "Services"
    },
    "join": "Join"
  },
  "content": {
    "header": {
      "buttonTransparent": "Process your requests 5 times faster with Genie Map",
      "title": "Unified service for real estate agencies in UAE",
      "description": "An interactive map with advanced filtering for quick search of off-plan projects from the developers and real estate agencies, a branded catalog generator, the best platform for video calls with your customers and many more for comfortable and efficient work of your real estate agency.",
      "join": "Join",
      "learnMore": "Learn more"
    },
    "developers": {
      "title": "Extencive amount of Developers",
      "description": "In Genie Map you will find 260+ developers, 1250+ projects and launches, <br> which you can add <a href='#catalogs'> In your branded catalogs.</a>"
    },
    "service": {
      "title": "The best platform <br /> for a real estate agency",
      "description": "We have comprehensive knowledge of the market nature and brokers' and agencies' needs. In our every day work, we gather information about new developers and projects. We update the information on them in real-time to be sure that you always have access to the most up-to-date information and projects.",
      "item1Title": "Filter",
      "item1Description": "An advanced filter system will help you find a perfect project for your client. Apply filter by the emirate, district, developer, prices, handover date, launch or ready building, and much more.",
      "item2Title": "Upcoming launches",
      "item2Description": "We add new launches constantly, enlarge the amount of projects, which can be offered to the customers, you can easily start collecting EOI from the clients long before the official launch date.",
      "item3Title": "Individual catalog for each client",
      "item3Description": "A broker can create a catalog with several projects for a client and send only one <a href='#catalogs'>file</a> with all the necessary information that fully suits the customer’s request.",
      "item4Title": "Well informed",
      "item4Description": "Genie Map — is a perfect database with all the developers and districts of the UAE. Each district contains a unique description, allowing broker to give more information about the benefits and features of certain area to the client."
    },
    "catalog": {
      "title": "The catalogs branded with your Logo",
      "description": "In 5 seconds, you will have a catalog branded with the logo of your <br> agency  with any projects and units from the developers.",
      "join": "Start generating catalogs for the clients"
    },
    "functionality": {
      "title": "The platform, which solves every <br /> possible task of the real estate agency",
      "listItem1": "Raise your personal or online appointments with customers on a completely new level. In Genie Map we use the Logo and the marketing name of your agency, so you will have the platform branded by your logo and your name.",
      "listItem2": "Use Genie Map as a training platform for inexperienced brokers to raise their knowledge of the market.",
      "block1Title": "Units actualization",
      "block1Description": "Our map is being updated every day. You will always be well informed about units availability without endless calls and messages to the sales managers of the developer.",
      "block2Title": "Comfortable online meetings",
      "block2Description": "Organize your online meetings, share your screen and show all the necessary information like available projects, districts and infrastructure in a user-friendly interface branded with the logo of your company.",
      "block3Title": "The description of UAE districts",
      "block3Description": "Use a wide database with the description of the UAE districts. Increase your knowledge of the market and personal skills, to always provide relevant offers to the customers.",
      "block4Title": "Information about every project",
      "block4Description": "Provide your client with complete information on any project he is interested in. Give the answer to any question and successfully make your sale.",
      "block5Title": "Database of the sales manager's contacts",
      "block5Description": "Use the database of the sales manager's contacts to increase amount of developers your agency is working with.",
      "block6Title": "Global customization",
      "block6Description": "Customize the platform for you and your client. Choose the language, currency, and measures. Make your search more comfortable and accurate."
    },
    "joinForm": {
      "title": "Start using <br /> GenieMap today",
      "description": "Leave a sign up request, and our manager <br /> will swiftly contact you.",
      "name": "Your Name",
      "tel": "Contact number",
      "position": {
        "name": "Position",
        "select": "Select",
        "owner": "Owner/Top Manager of an Agency",
        "broker": "Broker",
        "developer": "Developer",
        "freelancer": "Freelancer",
        "realEstateBuyer": "Real Estate Buyer"
      },
      "companyName": "Company name",
      "privacyPolicy": "A agree with the <a href=\"./privacy-policy.html\" target=\"_blank\">privacy policy</a>",
      "join": "Request free access",
      "note": "Free access for 3 days"
    },
    "successForm": {
      "title": "Thank you for <br /> submitting your application",
      "description": "Our manager will contact you soon <br /> to clarify details."
    }
  },
  "footer": {
    "social": {
      "title": "GenieMap in social networks"
    },
    "legalInformation": {
      "title": "Company details",
      "privacyPolicy": "Privacy policy"
    },
    "contact": {
      "title": "Contact us"
    }
  },
  "cookie": {
    "text": "We use cookies to improve your browsing experience. By clicking \"I Accept\", you agree to the use of cookies.",
    "button": "I accept"
  }
}